export const SupportedApps = {
  beeApp: 'bee-app',
  exitIntentApp: 'ey-app',
  sidleApp: 'sd-app',
  crisperAi: 'crisper-app',
};

export const SupportedProducts = {
  SENDYBAY: 1,
  EMAILYARD: 2,
  CRISPER: 3,
  SIDLE: 4,
};
