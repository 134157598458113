/* eslint-disable */
// @ts-nocheck

import * as serviceWorker from './serviceWorker';
import {
  getAppToBoot, isEmailyard, mountBee, mountCrisper, mountEmailyard, mountSidle,
} from './utils/reactMountingHelpers';
import {isLocalhost} from './utils/environment';
import {Integrations} from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import {SupportedApps} from './utils/constants';

const EMAILYARD_SENTRY =
    'https://9f436f07951944e5b34ac7f0531abe31@o192270.ingest.sentry.io/4504900169826304';

if (!isLocalhost()) {
  if (isEmailyard()) {
    Sentry.init({
      dsn: EMAILYARD_SENTRY,
      integrations: [new Integrations.BrowserTracing()],

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
    });
  } else {
    Sentry.init({
      dsn: 'https://5a07199a0512459385d4df8ead5d436f@o192270.ingest.sentry.io/5268173',
      integrations: [new Integrations.BrowserTracing()],

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
    });
  }
}

window.SB_BOOT_APP = getAppToBoot();
window.mountBee = mountBee;
window.mountEmailyard = mountEmailyard;
window.mountCripser = mountCrisper;

function mountApp() {
  if (window.SB_BOOT_APP === SupportedApps.crisperAi) {
    mountCrisper();
  } else if (window.SB_BOOT_APP === SupportedApps.beeApp) {
    mountBee();
    delete window.mountEmailyard;
  } else if (window.SB_BOOT_APP === SupportedApps.sidleApp) {
    mountSidle();
  } else if (window.SB_BOOT_APP === SupportedApps.exitIntentApp) {
    mountEmailyard();
    delete window.mountBee;
  }
}

mountApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
