import { createContext } from 'react';

interface ProductContextProps {
  productConfig?: any;
  productCode: number;
  isEmailyard?: boolean;
  isCrisper?: boolean;
}

export const ProductContext = createContext<ProductContextProps>({
  productCode: -1,
});
