/* eslint-disable no-undef */

import { isCrisper, isEmailyard } from './reactMountingHelpers';

export const SENDYBAY_PRODUCT_ID = 1;
export const EMAILYARD_PRODUCT_ID = 2;
export const CRISPER_PRODUCT_ID = 3;

export function getApiHost() {
  if (isDevelopment()) {
    return 'http://localhost:8000';
  } else if (isEmailyard()) {
    return 'https://api.emailyard.com';
  } else if (isCrisper()) {
    return 'https://api.crisper.ai';
  } else {
    return 'https://accounts.sendybay.com';
  }
}

export function isLocalhost() {
  return (
    window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  );
}

export function isTestEnvironment() {
  return /test/.test(window.location.search);
}

export function isProduction() {
  return !isDevelopment();
}

export function isDevelopment() {
  if (['local.sendybay.com'].includes(window?.location?.hostname)) {
    return true;
  }
  return isLocalhost() || isTestEnvironment();
}

export function getStripeEnv() {
  if (isDevelopment()) {
    return 'test';
  } else {
    return 'live';
  }
}

// This is for when Bee App is loaded within Sendy
export function getEncryptedAccountId() {
  let tenantAccountId = window.encryptedAccountId;
  if (!isProduction()) {
    tenantAccountId = 'BA'; // prod_1
  }
  return tenantAccountId;
}

export function getEnvironmentName() {
  if (isProduction()) {
    return 'production';
  }
  return 'development';
}

export function getAppHost() {
  return `${window.location.protocol}//${window.location.host}`;
}
